import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import "../styles/header.scss"

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "images/logo-header.png" }) {
        childImageSharp {
          fixed(width: 178) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <div className="header">
      <div className="inner">
        <Link className="logo" to="/" aria-label="Home">
          <Img className="symbol" fixed={data.logo.childImageSharp.fixed} />
        </Link>
      </div>
    </div>
  )
}

export default Header
