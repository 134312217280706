import React from "react"

import "../styles/sidebar.scss"
import { Link, graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const Sidebar = ({ toggleMenu }) => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "images/logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 180) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const links = [
    { id: 0, name: "Home", to: "/" },
    { id: 1, name: "Airconditioning", to: "/airconditioning" },
    { id: 2, name: "Verwarming", to: "/verwarming" },
    { id: 3, name: "Nieuwbouw", to: "/nieuwbouw" },
    { id: 4, name: "Boilers", to: "/boilers" },
    { id: 5, name: "Sanitair", to: "/sanitair" },
    { id: 6, name: "Onderhoud", to: "/onderhoud" },
    { id: 9, name: "Contact", to: "/contact" },
  ]

  return (
    <div className="side-header">
      <div className="side-header-wrapper">
        <div className="side-header-logo">
          <Link to="/" aria-label="BKG logo">
            <Img fluid={data.logo.childImageSharp.fluid} />
          </Link>
        </div>
        <div className="side-header-menu">
          <nav>
            <ul>
              {links.map(link => (
                <li key={link.id}>
                  <Link
                    to={link.to}
                    activeClassName="active"
                    aria-label={link.name}
                  >
                    {link.name}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        </div>
        <div className="side-header-info">
          {/* <div className="side-header-info-social">
            <span>
              <a
                href="https://www.facebook.com/Jan-Uytterhaegen-bvba-1411856422364191/"
                aria-label="Facebook"
              >
                <FontAwesomeIcon
                  className="social-icon facebook"
                  icon={faFacebookSquare}
                />
              </a>
              <a
                href="https://www.linkedin.com/company/bkg-construct-bvba"
                aria-label="LinkedIn"
              >
                <FontAwesomeIcon
                  className="social-icon linkedin"
                  icon={faLinkedin}
                />
              </a>
              <a href="mailto:info@uytterhaegen.be" aria-label="Mailto">
                <FontAwesomeIcon
                  className="social-icon mailto"
                  icon={faEnvelopeSquare}
                />
              </a>
            </span>
          </div>
           */}
          <div className="side-header-info-contact"></div>
        </div>
      </div>
    </div>
  )
}

export default Sidebar
