import React from "react"

import "../styles/mainNav.scss"

const MainNav = ({ toggleMenuMobile }) => {
  return (
    <div className="main-nav" onClick={toggleMenuMobile}>
      <div className="toggle-nav">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  )
}

export default MainNav
