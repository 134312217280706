import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import gsap from "gsap"

import "../styles/App.scss"

import Footer from "../components/footer"
import Sidebar from "./sidebar"
import MainNav from "./mainNav"
import Header from "./header"
import Main from "./main"

function debounce(fn, ms) {
  let timer
  return () => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      timer = null
      fn.apply(this, arguments)
    }, ms)
  }
}

const Layout = props => {
  // Re-render after screen size changes
  const [dimensions, setDimensions] = useState({
    height: typeof window !== "undefined" && window.innerHeight,
    width: typeof window !== "undefined" && window.innerWidth,
  })

  useEffect(() => {
    // Prevent flashing
    //gsap.to("body", 0, { css: { visibility: "visible" } })

    let vh = dimensions.height * 0.01
    document.documentElement.style.setProperty("--vh", `${vh}px`)

    const debounceHandleResize = debounce(function HandleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      })
    }, 1000)

    window.addEventListener("resize", debounceHandleResize)

    return () => {
      window.removeEventListener("resize", debounceHandleResize)
    }
  })

  let tl = gsap.timeline()

  const [menuState, setMenuState] = useState({
    open: false,
  })

  function openMenu() {
    tl.to(".side-header", {
      duration: 0.1,
      x: "-300",
      ease: "Power1.easeIn",
    }).to(".main-nav", {
      delay: -0.1,
      duration: 0.1,
      x: "-300",
    })
    setMenuState({
      open: true,
    })
    gsap.set(".toggle-nav", { className: "toggle-nav open" })
    gsap.set(".side-header", { className: "side-header open" })
    //gsap.to("body", 0, { css: { overflow: "hidden" } })
  }

  function closeMenu() {
    tl.to(".side-header", {
      duration: 0.1,
      x: "300",
      ease: "Power1.easeOut",
    }).to(".main-nav", {
      delay: -0.1,
      duration: 0.1,
      x: "0",
    })
    setMenuState({
      open: false,
    })
    gsap.set(".toggle-nav.open", { className: "toggle-nav" })
    gsap.set(".side-header.open", { className: "side-header" })
    //gsap.to("body", 0, { css: { overflow: "auto" } })
  }

  function toggleMenu() {
    menuState.open === true ? closeMenu() : openMenu()
  }
  return (
    <div className="App">
      <div className="container">
        <div className="wrapper">
          <MainNav toggleMenuMobile={toggleMenu} />
          <Sidebar toggleMenuMobile={toggleMenu} />
          <Header />
          <Main dimensions={dimensions}>{props.children}</Main>
          <Footer />
        </div>
      </div>
    </div>
  )
}

export default Layout

export const heroImage = graphql`
  fragment heroImage on File {
    childImageSharp {
      fluid(quality: 90, maxWidth: 1600) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`

export const logoImage = graphql`
  fragment logoImage on File {
    childImageSharp {
      fluid(maxWidth: 180) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const featureImage = graphql`
  fragment featureImage on File {
    childImageSharp {
      fluid(
        sizes: "(max-width: 465px) 386px, (max-width: 640px) 560px, (max-width: 768px) 328px, (max-width: 1366px) 477px, 498px"
      ) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
    name
  }
`

export const columnImage = graphql`
  fragment columnImage on File {
    childImageSharp {
      fluid(
        sizes: "(max-width: 465px) 386px, (max-width: 640px) 560px, (max-width: 768px) 328px, (max-width: 1366px) 477px, 498px"
      ) {
        ...GatsbyImageSharpFluid
      }
    }
    name
  }
`

export const thumbs = graphql`
  fragment thumbs on File {
    childImageSharp {
      sizes(maxWidth: 1200) {
        originalName
        ...GatsbyImageSharpSizes
      }
      fluid(
        sizes: "(max-width: 465px) 386px, (max-width: 640px) 560px, (max-width: 768px) 217px, (max-width: 1366px) 235px, 296px"
      ) {
        ...GatsbyImageSharpFluid_withWebp
        originalName
      }
    }
  }
`

export const galleryImage = graphql`
  fragment galleryImage on File {
    childImageSharp {
      sizes(maxWidth: 1200) {
        originalName
        ...GatsbyImageSharpSizes
      }
      fluid(
        sizes: "(max-width: 465px) 440px, (max-width: 640px) 610px, 650px"
      ) {
        ...GatsbyImageSharpFluid_withWebp
        originalName
      }
    }
  }
`
