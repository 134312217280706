import React from "react"

const Main = props => {
  //console.log(props.dimensions.width)
  return (
    <div className="main">
      <div className="main-content">{props.children}</div>
    </div>
  )
}

export default Main
