import React from "react"
import { Link } from "gatsby"

import "../styles/footer.scss"

const footer = () => {
  return (
    <footer>
      <div className="inner">
        <div className="footer-content">
          <section>
            <h2>Contacteer ons</h2>
            <form
              name="contact"
              method="POST"
              data-netlify="true"
              netlify-honeypot="bot-field"
              action="/success"
            >
              <p class="hidden">
                <label>
                  Don’t fill this out if you're human:{" "}
                  <input name="bot-field" />
                </label>
              </p>
              <input type="hidden" name="form-name" value="contact" />
              <div className="fields">
                <div className="field half">
                  <label for="name">Naam</label>
                  <input type="text" name="name" id="name" required />
                </div>
                <div className="field half">
                  <label for="phone">Telefoon</label>
                  <input type="tel" name="phone" id="phone" required />
                </div>
                <div className="field">
                  <label for="email">Email</label>
                  <input type="email" name="email" id="email" required />
                </div>
                <div className="field">
                  <label for="message">Bericht</label>
                  <textarea name="message" id="message" required></textarea>
                </div>
              </div>
              <ul className="actions">
                <li>
                  <input type="submit" className="primary" value="Verstuur" />
                </li>
              </ul>
            </form>
          </section>
          <section>
            {/* <h2>Social</h2>
            <ul className="icons">
              <li>
                <a
                  href="https://www.facebook.com/Jan-Uytterhaegen-bvba-1411856422364191/"
                  aria-label="Facebook"
                >
                  <FontAwesomeIcon
                    className="social-icon facebook"
                    icon={faFacebookSquare}
                  />
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/bkg-construct-bvba"
                  aria-label="LinkedIn"
                >
                  <FontAwesomeIcon
                    className="social-icon linkedin"
                    icon={faLinkedin}
                  />
                </a>
              </li>
              <li>
                <a href="mailto:info@uytterhaegen.be" aria-label="Mailto">
                  <FontAwesomeIcon
                    className="social-icon mailto"
                    icon={faEnvelopeSquare}
                  />
                </a>
              </li>
            </ul>
             */}
            <h2>Openingsuren</h2>
            <ul>
              <li>
                Maandag <span>08.00 - 12.00 en 13.00 - 16.30</span>{" "}
              </li>
              <li>
                Dinsdag <span>08.00 - 12.00 en 13.00 - 16.30</span>
              </li>
              <li>
                Woensdag <span>08.00 - 12.00 en 13.00 - 16.30</span>
              </li>
              <li>
                Donderdag <span>08.00 - 12.00 en 13.00 - 16.30</span>
              </li>
              <li>
                Vrijdag <span>08.00 - 12.00 en 13.00 - 14.30</span>
              </li>
            </ul>
            <h2>Algemene info</h2>
            <ul>
              <li>
                <a href="tel:+32 (0)16 82 34 88">+32 (0)16 82 34 88</a>
              </li>
              <li>BE0863441936</li>
              <li>Gallicstraat 11 - 3300 Tienen</li>
            </ul>
          </section>
        </div>
        <p class="site-info">
          © Uytterhaegen Jan BV - 2020 All rights reserved. |{" "}
          <Link to="/voorwaarden"> Algemene voorwaarden</Link>
        </p>
      </div>
    </footer>
  )
}

export default footer
